<template>
  <div
    class='flex flex-col justify-center relative'
    :class='sessionSlotContainerClass'
    :style='`background-color: ${sessionSlotBackgroundColor}; border-color: ${sessionSlotBorderColor}; min-width: ${slotMinWidth};`'>
    <button
      class='h-full w-full px-px'
      :class='unclickableSessionSlotClass'
      :disabled='disabledSessionSlotButton'
      @click.stop='handleSlotClick'>
      <img v-if='isCurrentLiveSession'
        src='https://d3r2ol85dktaw0.cloudfront.net/conferences/common_images/live_icon.svg'
        class='absolute h-6'
        style='right:0.2rem;'
        :style='currentLiveIconStyle'>
      <div v-if='showHeartButton'
        class='absolute rounded-md border border-transparent h-6 w-6 flex flex-row items-center hover:bg-gray-200 hover:border-gray-500'
        style='left:0.2rem;'
        :style='heartedStatusIconStyle'
        @click.stop='toggleHearted'>
        <component :is='whichHeartIcon' class='h-4'/>
      </div>
      <div 
        class='flex justify-center items-center text-xs leading-4'
        :class='singleOrMultiRowClass'
        :style='sessionSlotCategoryStyle'>
        <v-clamp
          :max-lines='sessionTitleMaxRows'
          location='middle'
          class='font-semibold whitespace-pre-line'
          :style='sessionTitleStyle'>
          {{ session.title }}
        </v-clamp>
        <div v-if='showSessionSubtitle'
          class='whitespace-pre-line'
          :style='sessionSubtitleStyle'>
          {{ session.subtitle }}
        </div>
        <h4 v-if='showSessionTime'
          class=''
          :style='sessionTimeStyle'>
          {{ sessionTimeString }}
        </h4>
      </div>
    </button>
    <button v-if='!hideShowDetailButton'
      class='absolute bottom-0 right-0 border border-transparent flex flex-row items-center hover:bg-gray-200 hover:border-gray-500 cursor-pointer p-1 uppercase'
      style='font-size: 0.5rem; line-height: 0.75rem;'
      @click.stop='openDetails'>
      {{viewSessionDetailsString}}
    </button>
  </div>
</template>

<script>
import VClamp from 'vue-clamp'
import { mapState, mapGetters, mapActions } from 'vuex'
import { HeartIcon as HeartOutline, InformationCircleIcon } from '@vue-hero-icons/outline'
import { HeartIcon as HeartSolid } from '@vue-hero-icons/solid'
import dayjs    from 'dayjs'
import utc      from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'ProgramSessionSlot',
  props: {
    session: {
      type: Object,
      required: true,
    },
    programSlotMinWidth: {
      type: String,
      required: false,
    },
  },
  components: {
    InformationCircleIcon,
    HeartOutline,
    HeartSolid,
    VClamp,
  },
  data () {
    return {
      sessionDetailsFromResponse: null,
    }
  },
  watch: {
    '$route.query.session_id': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal &&
            parseInt(newVal) === this.session.id) {
          this.openProgramSessionDetailsModal(this.session.id)
        }
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState ('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'eventConfigSessionSlots',
      'showingEventId',
      'eventConfigSessionOfflineMessage',
      'eventProgramsScheduleConfigurations',
    ]),
    ...mapGetters('sessions', [
      'isLiveSession',
    ]),
    isEndedLiveSession () {
      return this.isSessionTypeLive && this.session.endTime
    },
    isCurrentLiveSession () {
      return this.isLiveSession(this.session)
    },
    isSessionTypeLive () {
      return this.session.sessionType === 'live_stream'
    },
    isSessionTypeVod () {
      return this.session.sessionType === 'vod'
    },
    isSessionTypePoster () {
      return this.session.sessionType === 'poster'
    },
    isSessionTypeOffline () {
      return this.session.sessionType === 'offline'
    },
    isSessionTypeNotStarted () {
      return this.session.sessionType === 'not_started'
    },
    isSessionTypeBreak () {
      return this.session.sessionType === 'break'
    },
    isSessionTypeExternalLink () {
      return this.session.sessionType === 'external_link'
    },
    showSessionTime () {
      return this.session.configurations.showSessionTime == undefined || // basically only hide if configuration exists and its false
             this.session.configurations.showSessionTime == true
    },
    showSessionSubtitle () {
      return this.session.subtitle &&
             (this.session.configurations.showSessionSubtitle == undefined || this.session.configurations.showSessionSubtitle)
    },
    viewSessionDetails () {
      return this.session.configurations.showSessionDetailsPreview ||
             this.session.configurations.slotOpenDetailsFirstCondition
    },
    openDetailsFirst () {
      return this.session.configurations.slotOpenDetailsFirstCondition
    },
    blockViewingBeforeStartTime () {
      return this.session.configurations.blockViewingBeforeStartTime
    },
    blockViewingBeforeStartTimeMessage () {
      return this.session.configurations.blockViewingBeforeStartTimeMessage
    },
    blockViewingBeforeStartTimeButtonText () {
      return this.session.configurations.blockViewingBeforeStartTimeButtonText
    },
    sessionSlotContainerClass () {
      return this.session.me.myLikeStatus ? 'border rounded shadow-md opacity-100' : 'opacity-90'
    },
    sessionDetailUrl () {
      return (this.session.configurations.sessionDetailUrl) ? this.session.configurations.sessionDetailUrl : ''
    },
    slotMinWidth () {
      return (this.programSlotMinWidth) ? this.programSlotMinWidth : '12rem'
    },
    sessionSlotCategoryStyle () {
      if (this.eventConfigSessionSlots.length > 0) {
        let slot = this.eventConfigSessionSlots.find(slot => slot.category === this.session.category)
        if (slot) {
          return slot.defaultCssStyle
        } else {
          return this.eventConfigSessionSlots.find(slot => slot.category === 'others').defaultCssStyle
        }
      } else {
        return ''
      }
    },
    defaultSessionTitleStyle () {
      return this.eventProgramsScheduleConfigurations.defaultSessionTitleStyle || ''
    },
    defaultSessionSubtitleStyle () {
      return this.eventProgramsScheduleConfigurations.defaultSessionSubtitleStyle || ''
    },
    defaultSessionTimeStyle () {
      return this.eventProgramsScheduleConfigurations.defaultSessionTimeStyle || ''
    },
    sessionTitleStyle () {
      let sessionConfigStyle = this.session.configurations.sessionTitleStyle || ''
      return `${this.defaultSessionTitleStyle}; ${sessionConfigStyle}`
    },
    sessionSubtitleStyle () {
      let sessionConfigStyle = this.session.configurations.sessionSubtitleStyle || ''
      return `${this.defaultSessionSubtitleStyle}; ${sessionConfigStyle}`
    },
    sessionTimeStyle () {
      let sessionConfigStyle = this.session.configurations.sessionTimeStyle || ''
      return `${this.defaultSessionTimeStyle}; ${sessionConfigStyle}`      
    },
    showHeartButton () {
      return this.eventProgramsScheduleConfigurations.showHeartButton && this.hasValidToken
    },
    disabledSessionSlotButton () {
      return this.isEndedLiveSession || this.isSessionTypeNotStarted || this.isSessionTypeBreak
    },
    unclickableSessionSlotClass () {
      if (this.isEndedLiveSession) {
        return 'cursor-default opacity-25'
      } else if (this.isSessionTypeNotStarted || this.isSessionTypeBreak) {
        return 'cursor-default'
      } else {
        return ''
      }
    },
    heartedStatusIconStyle () {
      let style = ''
      style = (this.session.me.myLikeStatus) ? style + `color: ${this.sessionSlotBorderColor};` : style + 'color: #AAA;'
      style = (this.sessionLength < 20) ? style + ` top: 0px;` : style + ' top: 0.2rem;'
      return style
    },
    currentLiveIconStyle () {
      return (this.sessionLength < 20) ? `top: 0px;` : 'top: 0.2rem;'
    },
    whichHeartIcon () {
      return (this.session.me.myLikeStatus) ? 'heart-solid' : 'heart-outline'
    },
    sessionSlotBorderColor () {
      if (this.eventConfigSessionSlots.length > 0) {
        let slot = this.eventConfigSessionSlots.find(slot => slot.category === this.session.category)
        if (slot) {
          return slot.borderColor
        } else {
          return this.eventConfigSessionSlots.find(slot => slot.category === 'others').borderColor
        }
      } else {
        return ''
      }
    },
    sessionSlotBackgroundColor () {
      if (this.eventConfigSessionSlots.length > 0) {
        let slot = this.eventConfigSessionSlots.find(slot => slot.category === this.session.category)
        if (slot) {
          return slot.backgroundColor
        } else {
          return this.eventConfigSessionSlots.find(slot => slot.category === 'others').backgroundColor
        }
      } else {
        return ''
      }
    },
    sessionTimeString () {
      return `${dayjs(this.session.scheduledStartTime).tz("Asia/Seoul").format('HH:mm')}-${dayjs(this.session.scheduledEndTime).tz("Asia/Seoul").format('HH:mm')}`
    },
    sessionLength () {
      let startTime = dayjs(this.session.scheduledStartTime)
      let endTime = dayjs(this.session.scheduledEndTime)
      return endTime.diff(startTime, 'minute')
    },
    sessionTitleMaxRows () {
      if (this.session.configurations.slotTitleMaxRowCount) {
        return this.session.configurations.slotTitleMaxRowCount
      } else if (this.eventProgramsScheduleConfigurations.slotTitleMaxRowCount) {
        return this.eventProgramsScheduleConfigurations.slotTitleMaxRowCount
      } else if (this.sessionLength < 20) {
        return 1  
      } else {
        return 8 
      }    
    },
    isSingleLineTitle () {
      return this.sessionTitleMaxRows === 1
    },
    singleOrMultiRowClass () {
      return (this.isSingleLineTitle) ? 'flex-row gap-x-2' : 'flex-col gap-y-1'
    },
    viewSessionDetailsString () {
      return (this.session.configurations.viewSessionDetailsString) ? this.session.configurations.viewSessionDetailsString : 'show details'
    },
    hideShowDetailButton () {
      return this.session.configurations.slotOpenDetailsFirstCondition
    },
  },
  methods: {
    ...mapActions('sessions', [
      'postLikeSession',
      'goToCurrentSession',
    ]),
    ...mapActions('userActions', [
      'createUserAction',
    ]),
    ...mapActions([
      'openProgramSessionDetailsModal',
    ]),
    openDetails () {
      this.openProgramSessionDetailsModal(this.session.id)
    },
    goToPageBySessionType () {
      this.$modal.hide('details-modal')
      let query = Object.assign({}, this.$route.query)
      if (this.isSessionTypeLive) {
        this.goToCurrentSession(this.session.id)
      } else if (this.isSessionTypeVod) {
        query.session_id = this.session.id
        this.$router.push({name: 'Session', query: query}).catch(() => {})
      } else if (this.isSessionTypePoster) {
        this.$router.push({name: 'Poster', query: query}).catch(() => {})
      } else if (this.isSessionTypeExternalLink) {
        this.createUserAction({
          userActionName: 'open_session_external_link',
          userActionData: {
            event_id: this.showingEventId,
            session_id: this.session.id,
            content_id: this.session.configurations.externalLinkUrl
          }
        })
        window.open(this.session.configurations.externalLinkUrl, '_blank')
      } else if (this.isSessionTypeOffline) {
        this.$alert(`${this.eventConfigSessionOfflineMessage}`, {
          confirmButtonText: 'OK',
          type: 'info'
        })
      }
    },
    handleSlotClick () {
      if (this.openDetailsFirst) {
        this.openProgramSessionDetailsModal(this.session.id)
      } else if (this.blockViewingBeforeStartTime && new Date() < new Date(this.session.scheduledStartTime)) {
        this.$alert(`${this.blockViewingBeforeStartTimeMessage}`, {
          confirmButtonText: this.blockViewingBeforeStartTimeButtonText,
          type: 'info'
        })
      } else {
        this.goToPageBySessionType()
      }
    },
    toggleHearted () {
      this.postLikeSession(this.session.id)
    },
  }
}
</script>
