<template>
  <div>
    <div class='flex flex-col lg:flex-row lg:justify-between gap-y-2 lg:gap-x-2'>
      <div class='flex-grow flex flex-row justify-between'>
        <div class='flex flex-row justify-start items-center gap-x-2'>
          <calendar-icon class='inline-block' size='1.5x'/>
          <span class='font-semibold'>{{ dailySessionDate }}</span>
        </div>
        <select
          v-if='isMobileListLayout && isMobile'
          v-model='selectedLocation'
          class='border border-gray-200 rounded-md px-4 py-2 text-sm'>
          <option v-for='(location, index) in dailySessionLocation'
            :value='location'
            :key='`location-${index}`'>
            {{ location }}
          </option>
        </select>
      </div>
      <div v-if='programScheduleFilterAltContent' 
        v-html='programScheduleFilterAltContent'></div>
      <button v-else
        @click='openFilters'
        class='uppercase py-2 px-2 rounded border border-gray-200 hover:border-gray-300 hover:bg-gray-100 hover:shadow flex flex-row justify-start items-center gap-x-2'>
        <filter-icon class='h-5' />
        <span class='text-sm font-semibold'>filters: </span>
        <span class='text-xs text-gray-600 leading-relaxed'>{{currentFiltersString}}</span>
      </button>
    </div>
    <div v-if='hasDailySessionsDetail'
      :class='widthOfProgramsView'>
      <div v-if='isMobileListLayout && isMobile'>
        <program-session-slot
          v-for='session in dailySessionsDetailByLocation'
          :key='`session-${session.id}`'
          class='mt-4 text-center h-40 shadow-md'
          :session='session'/>
      </div>
      <div v-else
        class='mt-4 lg:mt-8 text-center bg-gray-50 text-sm overflow-x-auto grid'
        :style='scheduleStyle'>
        <!-- column header(room) -->
        <h2 v-for='(room, index) in dailySessionLocation'
            :key='`room-${room}`'
            :style='`grid-column: col-${index}; grid-row: rooms; min-width: ${slotMinWidth}`'
            class='font-semibold text-gray-700 bg-gray-200 uppercase flex flex-row items-center justify-center py-4'
            :class='clickEnabledRoomHeader'
            @click='goToRoomSession(room)'>
          {{ room }}
          <play-icon 
            v-if='liveSessionIdForRoom(room)'
            class='h-4 inline-block' 
            :style='themeTextColorStyle' />
        </h2>
        <h2 class='font-semibold text-gray-700 bg-gray-200 uppercase flex flex-col justify-center py-4'>time</h2>
        <!-- row header(time) -->
        <h2 v-for='time in timeArray'
            :key='`time-${time}`'
            :style='`grid-row: time-${time}; `'
            :class='markedTimeClass(time)'>
          {{ markedTimeString(time) }}
        </h2>
        <!-- marked time grid -->
        <div v-for='time in markedTimeArray'
             :key='`sharpTime-${time}`'
             :style='`grid-column: col-0-start/col-${dailySessionLocationWithoutSpace.length-1}-end; grid-row: time-${time}`'
             class='border-t border-solid border-gray-300 opacity-50'>
        </div>
        <!-- half marked time grid -->
        <div v-for='time in halfMarkedTimeArray'
             :key='`sharpTime-${time}`'
             :style='`grid-column: col-0-start/col-${dailySessionLocationWithoutSpace.length-1}-end; grid-row: time-${time}`'
             class='border-t border-dashed  border-gray-300 opacity-50'>
        </div>
        <!-- sessions -->
        <div v-for='session in dailySessionsDetail'
             :key='`session-${session.id}`'
             :style='`grid-column: ${sessionGridColumnStyle(session)}; grid-row: time-${sessionRowStartTimeStyle(session)} / time-${sessionRowEndTimeStyle(session)};`'>
          <program-session-slot
            class='h-full'
            :session='session'
            :program-slot-min-width='slotMinWidth'/>
        </div>
      </div>
    </div>
    <div v-else
      class='mt-4 lg:mt-8 py-36 bg-gray-50 text-center opacity-75 text-sm lg:text-base'>
      There are no sessions on {{ dailySessionDate }} that match the selected filters. <br>
      Please check a different day, or update your filters.
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions }   from 'vuex'
import { CalendarIcon, FilterIcon, PlayIcon } from '@vue-hero-icons/outline'
import dayjs                                  from 'dayjs'
import ProgramSessionSlot    from '@/components/programs/ProgramSessionSlot.vue'
import DateTimeStringHelpers from '@/utils/date-time-string-helpers'
import utc      from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'ProgramByDay',
  data () {
    return {
      selectedLocation: ''
    }
  },
  props: [
    'dailySessions',
    'showScheduleFullWidth',
    'programSlotSize',
    'eventProgramMobileLayout',
  ],
  components: {
    ProgramSessionSlot,
    CalendarIcon,
    FilterIcon,
    PlayIcon,
  },
  watch: {
    dailySessionLocation: {
      handler: function (newVal) {
        if (newVal.length > 0) {
          this.selectedLocation = newVal[0]
        }
      },
      immediate: true,
      deep: true
    },
    dailySessionsDetail: {
      handler: function (newVal) {
        if (newVal.length > 0 && this.$route.query.session_id) {
          let index = this.dailySessionsDetail.findIndex(session => session.id === parseInt(this.$route.query.session_id))
          if (index === -1) {
            let query = Object.assign({}, this.$route.query)
            delete query.session_id
            this.$router.replace({ query })
          }
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapState('sessions', [
      'sessionCategoryFilters',
      'currentSessionId',
    ]),
    ...mapGetters('sessions', [
      'hasSessionCategoryFilters',
      'isLiveSession',
    ]),
    ...mapGetters('events', [
      'eventConfigSessionSlotHeight',
      'themeTextColorStyle',
      'dateLocaleString',
      'eventProgramsScheduleConfigurations',
      'programScheduleFilterAltContent',
    ]),
    ...mapGetters([
      'isMobile',
    ]),
    isMobileListLayout () {
      return this.eventProgramMobileLayout.toLowerCase() === 'list'
    },
    widthOfProgramsView () {
      return (this.showScheduleFullWidth) ? 'full-width-inside-layout-container' : ''
    },
    enableRoomHeaderNavigation () {
      return this.eventProgramsScheduleConfigurations.enableRoomHeaderNavigation
    },
    clickEnabledRoomHeader () {
      return (this.enableRoomHeaderNavigation) ? 'cursor-pointer' : ''
    },
    currentFiltersString () {
      return (this.hasSessionCategoryFilters) ? this.sessionCategoryFilters.join(', ') : 'show all'
    },
    dailySessionDate () {
      return (this.dailySessions) ? DateTimeStringHelpers.dateToDateWithDay(this.dailySessions.date, this.dateLocaleString) : ''
    },
    dailySessionLocation () {
      return (this.dailySessions) ? this.dailySessions.locations : []
    },
    dailySessionLocationWithoutSpace () {
      return this.dailySessionLocation.map(e => e.replace(/ /g,''))
    },
    dailySessionScheduleStartTime () {
      return (this.dailySessions) ? this.dailySessions.scheduleStartTime : '700'
    },
    dailySessionScheduleEndTime () {
      return (this.dailySessions) ? this.dailySessions.scheduleEndTime : '2100'
    },
    dailySessionsDetail () {
      return (this.dailySessions) ? this.dailySessions.sessions.filter(session => !session.configurations.hideSessionInProgramAtAGlance) : []
    },
    dailySessionsNowLive () {
      return this.dailySessionsDetail.filter(session => this.isLiveSession(session))
    },
    hasDailySessionsDetail () {
      return this.dailySessionsDetail.length
    },
    dailySessionsDetailByLocation () {
      return this.dailySessionsDetail.filter(session => session.locations.includes(this.selectedLocation))
    },
    slotMinWidth () {
      return (this.programSlotSize) ? this.programSlotSize : '12rem'
    },
    timeArray () {
      let array = []
      for (var i = parseInt(this.dailySessionScheduleStartTime);
           i < parseInt(this.dailySessionScheduleEndTime);
           i = i + 100) {
        for (var j = 0; j < 60; j = j + 5) {
          if (i+j >= parseInt(this.dailySessionScheduleEndTime)) {
            break
          }
          array.push(i + j)
        }
      }
      return array
    },
    markedTimeArray () {
      return this.timeArray.filter(time => parseInt(time) % 100 == 0)
    },
    halfMarkedTimeArray () {
      return this.timeArray.filter(time => parseInt(time - 30) % 100 == 0)
    },
    scheduleStyle () {
      return 'grid-gap: 2px; grid-template-rows:[rooms] auto' +
        this.scheduleGridTemplateRowsStyle +
        '; grid-template-columns: [times] 4rem' +
        this.scheduleGridTemplateColumnsStyle + ';'
    },
    scheduleGridTemplateRowsStyle () {
      //times
      let styleString = ''
      let sessionOnTheTime

      for (var i = parseInt(this.dailySessionScheduleStartTime);
           i <= parseInt(this.dailySessionScheduleEndTime);
           i = i + 100) {
        for (var j = 0; j < 60; j = j + 5) {
          let number = i + j
          let sessionSlotHeight = 0

          // find the session at the given time
          if (!sessionOnTheTime || dayjs(sessionOnTheTime.scheduledEndTime).tz("Asia/Seoul").format('Hmm') <= number) {
            sessionOnTheTime = this.dailySessionsDetail.find(session => dayjs(session.scheduledStartTime).tz("Asia/Seoul").format('Hmm') <= number && number < dayjs(session.scheduledEndTime).tz("Asia/Seoul").format('Hmm'))
          }

          let sessionLength = (sessionOnTheTime) ? dayjs(sessionOnTheTime.scheduledEndTime).tz("Asia/Seoul").diff(dayjs(sessionOnTheTime.scheduledStartTime).tz("Asia/Seoul"), 'minute') : 0

          // if there is the session length is less than 15 minutes, set session slot height to be same as a 15 minute session
          if (sessionOnTheTime && sessionLength < 15) {
            sessionSlotHeight = (this.eventConfigSessionSlotHeight * 15) / sessionLength
          } else {
            sessionSlotHeight = this.eventConfigSessionSlotHeight
          }

          styleString += `[time-${number}] ${sessionSlotHeight}px `
          if (number >= parseInt(this.dailySessionScheduleEndTime)) {
            break
          }
        }
      }
      return styleString
    },
    scheduleGridTemplateColumnsStyle () {
      //rooms
      let styleString = ''
      this.dailySessionLocationWithoutSpace.forEach((e, index) => {
        if (index === 0) {
          styleString += `[col-${index}-start] 1fr `
          if (this.dailySessionLocationWithoutSpace.length > 1) {
            styleString += `[col-${index}-end col-${index + 1}-start] 1fr `
          } else {
            styleString += `[col-${index}-end]`
          }
        } else if (index === this.dailySessionLocationWithoutSpace.length - 1) {
          styleString += `[col-${index}]`
        } else {
          styleString += `[col-${index}-end col-${index + 1}-start] 1fr `
        }
      })
      return styleString
    }
  },
  methods: {
    ...mapActions([
      'sidepanelOpen'
    ]),
    ...mapActions('sessions', [
      'getCurrentSessionId',
      'goToCurrentSession',
    ]),
    openFilters () {
      this.sidepanelOpen({ componentName: 'session-filters' })
    },
    goToRoomSession (roomString) {
      if (this.enableRoomHeaderNavigation) {
        let roomLiveSesisonId = this.liveSessionIdForRoom(roomString)
        if (roomLiveSesisonId) {
          this.goToCurrentSession(roomLiveSesisonId)
        } else {
          this.$alert('There are no sessions in progress', {
            confirmButtonText: 'Done',
            type: 'warning'
          })
        }
      }
    },
    liveSessionIdForRoom (roomString) {
      let liveSessionForRoom = this.dailySessionsNowLive.find(session => session.locations.includes(roomString))
      if (liveSessionForRoom) {
        return liveSessionForRoom.id
      } else {
        return 0
      }
    },
    markedTimeString (time) {
      let timeString = time.toString()
      let minute = timeString.slice(-2)
      let hour = timeString.slice(0, -2)
      return (parseInt(time) % 100 == 0 || parseInt(time - 30) % 100 == 0) ? dayjs().hour(hour).minute(minute).format('HH:mm') : ''
    },
    markedTimeClass (time) {
      if (parseInt(time) % 100 == 0) {
        return 'text-xs border-t border-solid font-semibold'
      } else if (parseInt(time - 30) % 100 == 0) {
        return 'text-xs border-t border-dashed opacity-50'
      } else {
        return 'hidden'
      }
    },
    sessionGridColumnStyle (session) {
      let sessionLocationWithoutSpace = session.locations.map(location => location.replace(/ /g,''))
      let sessionStartIndex = this.dailySessionLocationWithoutSpace.findIndex(location => location === sessionLocationWithoutSpace[0])
      let sessionEndIndex = this.dailySessionLocationWithoutSpace.findIndex(location => location === sessionLocationWithoutSpace[session.locations.length - 1])
      return `col-${sessionStartIndex}-start / col-${sessionEndIndex}-end`
    },
    sessionRowStartTimeStyle (sessions) {
      return dayjs(sessions.scheduledStartTime).tz("Asia/Seoul").format('Hmm')
    },
    sessionRowEndTimeStyle (sessions) {
      return dayjs(sessions.scheduledEndTime).tz("Asia/Seoul").format('Hmm')
    }
  }
}
</script>
